import { render, staticRenderFns } from "./PosCaisse.vue?vue&type=template&id=00e21c30&scoped=true&"
import script from "./PosCaisse.vue?vue&type=script&lang=js&"
export * from "./PosCaisse.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00e21c30",
  null
  
)

export default component.exports