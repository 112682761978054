<template>
  

<div  class="card rounded-lg shadow border border-white bg-white h-100 mb-0 pb-3 overflow-auto" >
    <div class="card-header bg-transparent">
             
           

<div class="row">


    <div class="col">
        <h5 class="ml-2 text-secondary font-weight-light"> <span class="fa fa-file-alt"></span> Gestion Des Catégories Et Articles Des POS : <span class="ml-2 text-success"> </span> </h5>
    </div>

                <div class="col-5 p-0">
                        <b-button pill v-b-toggle.collap-categ.collap-articl  class=" ml-0 mr-3 pt-1 pb-1 pr-2 pl-2 float-right" variant="outline-primary">
                        <i class="fa fa-edit fa-1x"></i>     

                        <span> Catégories-Articles</span>           
                        </b-button> 
                </div>

</div>


    </div>




    <div class="row card-body h-100 p-0 m-0">

  
                <b-collapse id="collap-categ"   class="col-2 h-100 mr-0 ml-0 mt-2 p-0">

                            <div class="card border-success h-100 overflow-auto ml-2">
                            <div class="card-header  bg-success border-success p-1">
                                            <b-form-input v-model="searchCateg" class="border-info" id="search-cat" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                                           
                            </div>

                            <b-list-group flush >
                            <b-list-group-item  v-for="item in filterCategorie" :key="item.id" button class="p-1" variant="success" @click="addcat_click(item)" >
                                    {{ item.nom }} 
                                    <p> <small>{{item.description}}</small> </p>

                            </b-list-group-item>

                            </b-list-group>
                            </div>



                    
                </b-collapse>

                <div class="col-3 h-100 overflow-auto mt-2 mr-0 ml-0">
                            <div class="btn card border border-light shadow">

                                    <div class=" d-flex justify-content-center pt-1 text-info mb-2">
                                            <h5 class="font-weight-light ">Catégories Des POS </h5>
                                    </div>

                                    <b-table 
                                    sort-icon-left
                                    small 
                                    responsive 

                                    :fields="fieldsCatCaise" 
                                    :items="listCatCaisse"
                                    >
                                        <template #cell(actions)="row">
                                                <b-button pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="Dellcat_click(row.item)">
                                                            <i class="fa fa-trash-alt"></i>
                                                    
                                                </b-button>

                                                


                                                <b-button pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-primary" @click="cat_click(row.item)">
                                                            <i class="fa fa-bars"></i>
                                                    
                                                </b-button>
                                        </template>
                                    
                                    </b-table>

                            </div>
                </div>

                <b-collapse id="collap-articl"   class="col-2 h-100 mr-0 mt-2 p-0">


                            <div class="card border-info h-100  overflow-auto">
                            <div class="card-header  bg-info border-info p-1">
                                <b-form-input v-model="serchArt"  class="border-info" id="search-art" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                            
                                        <div class="custom-control custom-switch form-control-sm ml-1">
                                        <input type="checkbox"
                                        class="custom-control-input" 
                                        id="isCodeBarreSearch"
                                                v-model="isCodeBarreSearch"
                                                name="isCodeBarreSearch"
                                                value=true
                                                unchecked-value=false>

                                        <label class="custom-control-label text-white  mb-0" for="isCodeBarreSearch">Code Barre</label>
                                        </div>
                            </div>

                            <b-list-group flush  >
                       
                            <b-list-group-item  v-for="item in filterArticle" :key="item.id" button class="p-1" variant="info" @click="add_Article_click(item)">
                                 {{ item.ref }}
                                 <p> <small>{{item.description}}</small> </p>
                                 </b-list-group-item>

                          

                            </b-list-group>
                            </div>






                </b-collapse>

                <div class="col h-100 overflow-auto mt-2 mr-2 ml-0">



                            
                <div class="btn card border border-light shadow mt-0">
                        <div class=" d-flex justify-content-center pt-1 text-info mb-2">
                                <h5 class="font-weight-light ">{{currentPosCateg}} </h5>
                        </div>

                        <b-table 
                        sort-icon-left
                        small 
                        responsive 

                        :fields="fieldsArtCaise" 
                        :items="listArtCaisse"
                        >
                            <template #cell(actions)="rowAr">
                                    <b-button pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-primary" @click="edit_Article_click(rowAr.item)">
                                                <i class="fa fa-pencil-alt"></i>
                                        
                                    </b-button>


                                    <b-button pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="Dellart_click(rowAr.item.id)">
                                                <i class="fa fa-trash-alt"></i>
                                        
                                    </b-button>
                            </template>
                        
                        </b-table> 

                </div>


                       


                </div>

  </div>






    <!-- le modal -->
    <b-modal id="banqModal" title="Articles" size="lg" hide-footer>
           <form @submit.prevent="ArthandleSubmit">
            <div class="row">
            <div class="form-group col">
                <label for="ref">Référence</label>
                <input type="text" v-model="itemFormArt.ref" id="ref" name="ref" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemFormArt.ref.$error }" />
                <div v-if="isSubmitted && !$v.itemFormArt.ref.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>

            <div class="form-group col">

                            <label for="itemForm">Catégorie</label>
                            <select class="form-control" id="itemForm" v-model="itemFormArt.pos_categorie_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemFormArt.pos_categorie_id.$error }">
                            <option v-for="item in listCatCaisse" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>

            </div>
            </div>

            <div class="row">
                
            <div class="form-group col">
                <label for="code_bar">Code Barre</label>
                <input type="text" v-model="itemFormArt.code_bar" id="code_bar" name="code_bar" class="form-control"
                :class="{ 'is-invalid': isSubmitted && $v.itemFormArt.code_bar.$error }" />
                <div v-if="isSubmitted && !$v.itemFormArt.code_bar" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>



            <div class="form-group col">
                <label for="prix_vent">Prix Vente</label>
                <input type="text" v-model="itemFormArt.prix_vent" id="prix_vent" name="prix_vent" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemFormArt.prix_vent.$error }" />
                <div v-if="isSubmitted && !$v.itemFormArt.prix_vent" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemFormArt.prix_vent" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>



            </div>





            <div class="form-group">
            <label for="Description">Description</label>
            <textarea name="description" id="description"  rows="4" class="form-control" v-model="itemFormArt.description"></textarea>
            </div>

            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>

        </form>

    </b-modal>







      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";


export default {


 
    computed: {
            user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
     d_gst_entre_val() {
      return this.$store.getters.get_gst_entre_val;
    },
    filterArticle() {
            return this.listArt.filter(article => {

            if(this.isCodeBarreSearch)
            {
                if(article.code_bar)
                {
                return article.code_bar.toLowerCase().includes(this.serchArt.toLowerCase())
                }

            }
            else
            {
                return article.ref.toLowerCase().includes(this.serchArt.toLowerCase())
            }

      })
    },
    filterCategorie() {
            return this.listCat.filter(category => {
            return category.nom.toLowerCase().includes(this.searchCateg.toLowerCase())   
      })
    }
    },
    mounted(){
            this.fetchCategorieList()
            this.fetchListArts()

    },
    data () {
		return {

            listCatCaisse: [],
            fieldsCatCaise: [
                {key: 'nom', label: 'Catégories'},
                { key: 'actions', label: '' }
            ],
            listArtCaisse: [],
            fieldsArtCaise: [
                {key: 'ref', label: 'Article'},
                {key: 'prix_vent', label: 'Prix Vente'},
                { key: 'actions', label: '' }
            ],
            itemFormArt: {
                    id:'',
                    ref:'',
                    code_bar:'',
                    description:'',
                    prix_vent:'',
                    article_id:'',
                    pos_categorie_id:''},


            isCodeBarreSearch: false,
            searchCateg:'',
            serchArt:'',
            ArticlesList:[],
            listCat: [],
            listArt: [],
            itemForm: {
                    id:'',
                    ref:'',
                    code_bar:'',
                    description:'',
                    qte:'',
                    nom_lot:'',
                    lot_id:'',
                    article_id:'',
                    is_lot_manag:''},
            itemFormLot: {
                    nom:'',
                    qte:'',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    date_exp:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    depot_id:'',
                    article_id:''},
            ListLots:[],



            ModalType:'Ajouter',

            currentPosCateg:'',

            isSubmitted: false,
            isLoading: false
		}
    },
    validations: {
            itemFormArt: {
                ref: {
                    required
                },
                prix_vent: {
                    required,
                    decimal
                },
                article_id: {
                    required
                },
                pos_categorie_id: {
                    required,
                },
                code_bar: {
                    required
                }
            }

    },
    methods:{
        ArthandleSubmit() {

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                            if(this.ModalEdit)
                            {
                                this.banqueEdit()
                            }
                            else
                            {

                                this.itemslisttore()
                            }   

                }

        },
        itemslisttore(){
            this.isLoading=true;

            this.$http.post('/posArticles',{
                   
                    ref:this.itemFormArt.ref,
                    code_bar:this.itemFormArt.code_bar,
                    description:this.itemFormArt.description,
                    prix_vent:this.itemFormArt.prix_vent,
                    article_id:this.itemFormArt.article_id,
                    pos_categorie_id:this.itemFormArt.pos_categorie_id


                })
                .then(response => {

                    this.isLoading=false;
                    this.listArtCaisse.unshift(response.data.data);
                    this.$bvModal.hide('banqModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        banqueEdit(){



            this.isLoading=true;

            this.$http.patch('/posArticles/'+this.itemFormArt.id,{
                    ref:this.itemFormArt.ref,
                    code_bar:this.itemFormArt.code_bar,
                    description:this.itemFormArt.description,
                    prix_vent:this.itemFormArt.prix_vent,
                    article_id:this.itemFormArt.article_id,
                    pos_categorie_id:this.itemFormArt.pos_categorie_id

                })
                .then(response => {

                    this.isLoading=false;
                    this.$bvModal.hide('banqModal')

                    let index = this.listArtCaisse.findIndex(item => item.id === this.itemFormArt.id)
                    this.listArtCaisse.splice(index, 1,response.data.data)
                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })


                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },
        add_Article_click(item) {

            if(item.code_bar)
            {
                this.itemFormArt.code_bar=item.code_bar
            }
            else
            {
                this.itemFormArt.code_bar="-"
            }

                    this.itemFormArt.ref=item.ref,
                    this.itemFormArt.description=item.description,
                    this.itemFormArt.prix_vent=item.prix_vent,
                    this.itemFormArt.article_id=item.id
                    

        this.ModalEdit=false;
        this.ModalType='Ajouter'

        this.$root.$emit('bv::show::modal', 'banqModal')
        },
        edit_Article_click(item) {

                    this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                    .then(() => {

                        this.itemFormArt.id=item.id,
                        this.itemFormArt.ref=item.ref,
                        this.itemFormArt.code_bar=item.code_bar,
                        this.itemFormArt.description=item.description,
                        this.itemFormArt.prix_vent=item.prix_vent,
                        this.itemFormArt.article_id=item.article_id,
                        this.itemFormArt.pos_categorie_id=item.pos_categorie_id

                    this.ModalEdit=true;
                    this.ModalType='Modifier'

                    this.$root.$emit('bv::show::modal', 'banqModal')
                    })
                    .catch(error=> {
                    console.log(error)

                    });


        },
        Dellart_click(id_art)
        {
                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {

            this.isLoading=true;

            this.$http.delete('/posArticles/'+id_art)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                    let index = this.listArtCaisse.findIndex(item => item.id === id_art)

                    this.listArtCaisse.splice(index, 1)
                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });






                })
                .catch(error=> {
                console.log(error)

                });
        },


        addcat_click(item)
        {
                this.$confirm("Voulez-vous vraiment ajouter cet élément ??")
                 .then(() => {



            this.isLoading=true;

            this.$http.post('/posCategors',{
            categorie_id:item.id,
            nom:item.nom

                })
                .then(response => {

                    this.isLoading=false;
                    this.listCatCaisse.unshift(response.data.data);

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });






                })
                .catch(error=> {
                console.log(error)

                });
        },
        Dellcat_click(ite)
        {
                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {

            this.isLoading=true;

            this.$http.delete('/destroyPosCat/'+ite.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                    let index = this.listCatCaisse.findIndex(item => item.id === ite.id)

                    this.listCatCaisse.splice(index, 1)
                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });






                })
                .catch(error=> {
                console.log(error)

                });
        },
        fetchCategorieList: function () {
                    this.isLoading=true;
                    this.$http.get('/categories')
                    .then((result) => {
                                this.listCat = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        cat_click(item)
        {
                    this.isLoading=true;
                    this.$http.get('/articlesCat/'+item.categorie_id)
                    .then((result) => {
                                this.listArt = result.data.data

                                
                                this.isLoading=false;


                                //effacer le search:
                                this.serchArt=''
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


                    
                    this.isLoading=true;
                    this.$http.get('/posArtcat/'+item.id)
                    .then((result) => {
                                this.listArtCaisse = result.data.data
                                  this.isLoading=false;
                                    
                                    //le nom ui                              

                                    this.currentPosCateg= "Articles De " +item.nom

                                    this.itemFormArt.pos_categorie_id=item.id
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },



        fetchListArts()
        {
                    this.isLoading=true;
                    this.$http.get('/posCategors')
                    .then((result) => {
                                this.listCatCaisse = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
 


        },
        components: {
                    Loading      
        }


}
</script>

<style>
/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #9cc7f9;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #9cc7f9;
}
::-webkit-scrollbar-thumb:active {
  background: #9cc7f9;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 60px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

</style>